import React from "react";
import { useParams } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import { styled, Tab, Tabs } from "@mui/material";
import { LocaleContext } from "contexts/LocaleContext";
import { fetchFireAlarmSystem } from "adapters/fireAlarmSystem";
import SensorInfo from "components/systemInformation/SensorInfo";
import AlarmSystemBreadcrumbs from "components/AlarmSystemBreadcrumbs";


const SystemInformation: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const params = useParams();
    const alarmSystemId = params.id || "alarm";
    const [selectedSystemInfoTab, setSystemInfoTab] = React.useState("sensor-info")
    const {
        data,
        isLoading
    } = useQuery({
        queryKey: [alarmSystemId],
        queryFn: async () => {
            const response = await fetchFireAlarmSystem(alarmSystemId);
            return {
                data: response.data,
            };
        },
    });

    const TabsWithBorder = styled(Tabs)
        (({ theme }) => ({
            borderBottom: `1px solid ${theme.palette.secondary.main}`,
            background: theme.palette.background.paper
        })
        );

    const renderTabContent = (): React.ReactNode => {
        switch (selectedSystemInfoTab) {
            case "sensor-info":
                return <SensorInfo />
            default:
                return <SensorInfo />
        }
    }


    return (
        <>
            <AlarmSystemBreadcrumbs
                alarmSystem={data?.data} 
                isLoading={isLoading} 
                paths={["navigation.status", "navigation.system-information"]} />
            <TabsWithBorder variant="scrollable" value={selectedSystemInfoTab} onChange={(event, newValue) => setSystemInfoTab(newValue)}>
                <Tab key={0} value={"sensor-info"} label={localize('navigation.sensor-info')} />
            </TabsWithBorder>
            {renderTabContent()}
        </>
    );
};

export default SystemInformation;