import { createBrowserRouter } from "react-router-dom";

import BaseLayout from "components/BaseLayout";

import Login from "views/Login";
import Status from "views/Status";
import Operations from "views/Operations";
import NotFoundView from "views/404";
import HomeView from "views/HomeView";
import UserManagementView from "views/UserManagement";
import NoAuthLayout from "components/NoAuthLayout";
import AcceptInvitation from "views/AcceptInvitation";
import AccountView from "views/Account";
import AuthRequired from "components/AuthRequiried";
import LogoutView from "views/Logout";
import AlarmSystemConfigurationView from "views/AlarmSystemConfiguration";
import NotificationsView from "views/Notifications";
import SetNewPasswordView from "views/SetNewPassword";
import ResetPasswordView from "views/ResetPassword";
import Events from "views/Events";
import JournalView from "views/JournalView";
import MobileTabs from "views/MobileTabs";
import SystemInformation from "views/SystemInformation";
import SSDBackupView from "views/SSDBackupView";
import InvoiceView from "views/InvoiceView";


export const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthRequired main={<BaseLayout />} fallback={<NoAuthLayout />} />,
    errorElement: <NotFoundView />,
    children: [
      {
        path: "/",
        element:
          <AuthRequired
            main={<HomeView />}
            fallback={<Login />}
            permissionParams={["user", "view_profile"]}
          />,
      },
      {
        path: "/:local_office_id/status/:id",
        element:
          <AuthRequired
            main={
              <MobileTabs routes={[
                { label: "navigation.deviations", route: "status" },
                { label: "navigation.events", route: "events" },
                { label: "navigation.journal", route: "journal" },
                { label: "navigation.system-information", route: "system-information" }]}>
                <Status />
              </MobileTabs>}
            fallback={<Login />}
            permissionParams={["firealarmsystem", "view_firealarmsystem"]}
          />,
      },
      {
        path: "/:local_office_id/events/:id",
        element:
          <AuthRequired
            main={
              <MobileTabs routes={[
                { label: "navigation.deviations", route: "status" },
                { label: "navigation.events", route: "events" },
                { label: "navigation.journal", route: "journal" },
                { label: "navigation.system-information", route: "system-information" }]}>
                <Events />
              </MobileTabs>}
            fallback={<Login />}
            permissionParams={["firealarmsystem", "view_firealarmsystem"]}
          />,
      },
      {
        path: "/:local_office_id/system-information/:id",
        element:
          <AuthRequired
            main={
              <MobileTabs routes={[
                { label: "navigation.deviations", route: "status" },
                { label: "navigation.events", route: "events" },
                { label: "navigation.journal", route: "journal" },
                { label: "navigation.system-information", route: "system-information" }]}>
                <SystemInformation />
              </MobileTabs>
            }
            fallback={<Login />}
            permissionParams={["firealarmsystem", "view_firealarmsystem"]}
          />,
      },
      {
        path: "/:local_office_id/operations/:id",
        element:
          <AuthRequired
            main={
              <MobileTabs routes={[
                { label: "navigation.disablement", route: "operations" }]}>
                <Operations />
              </MobileTabs>}
            fallback={<Login />}
            permissionParams={["firealarmsystem", "view_firealarmsystem"]}
          />,
      },
      {
        path: "/:local_office_id/configuration/:id",
        element:
          <AuthRequired
            main={
              <MobileTabs routes={[
                { label: "navigation.configuration", route: "configuration" },
                { label: "navigation.notifications", route: "notifications" },
                { label: "navigation.ssd-files", route: "ssd-files", featureFlags: ["navigation_ssd_files"] }]}>
                <AlarmSystemConfigurationView />
              </MobileTabs>}
            fallback={<Login />}
            permissionParams={["firealarmsystem", "view_firealarmsystem"]}
          />
      },
      {
        path: "/:local_office_id/notifications/:id",
        element:
          <AuthRequired
            main={
              <MobileTabs routes={[
                { label: "navigation.configuration", route: "configuration" },
                { label: "navigation.notifications", route: "notifications" },
                { label: "navigation.ssd-files", route: "ssd-files", featureFlags: ["navigation_ssd_files"] }]}>
                <NotificationsView />
              </MobileTabs>}
            fallback={<Login />}
            permissionParams={["notificationpreference", "add_notificationpreference"]}
          />,
      },
      {
        path: "/:local_office_id/ssd-files/:id",
        element:
          <AuthRequired
          main={
            <MobileTabs routes={[
              { label: "navigation.configuration", route: "configuration" },
              { label: "navigation.notifications", route: "notifications" },
              { label: "navigation.ssd-files", route: "ssd-files", featureFlags: ["navigation_ssd_files"] }]}>
              <SSDBackupView />
            </MobileTabs>}
            fallback={<Login />}
            permissionParams={["ssdfileversion", "view_ssdfileversion"]}
          />,
      },
      {
        path: "/:local_office_id/journal/:id",
        element:
          <AuthRequired
            main={
              <MobileTabs routes={[
                { label: "navigation.deviations", route: "status" },
                { label: "navigation.events", route: "events" },
                { label: "navigation.journal", route: "journal" },
                { label: "navigation.system-information", route: "system-information" }]}>
                <JournalView />
              </MobileTabs>}
            fallback={<Login />}
            permissionParams={["journal", "view_journal"]}
          />,
      },
      {
        path: "users",
        element:
          <AuthRequired
            main={<UserManagementView />}
            fallback={<Login />}
            permissionParams={["user", "add_user"]}
          />,
      },
      {
        path: "invoice-export",
        element:
          <AuthRequired
            main={<InvoiceView />}
            fallback={<Login />}
            permissionParams={["invoiceexport", "view_invoiceexport"]}
          />,
      },
      {
        path: "/accept-invitation/:token",
        element:
          <AuthRequired
            main={<NotFoundView />}
            fallback={<AcceptInvitation />}
          />
      },
      {
        path: "/set-password/:id/:token/",
        element:
          <AuthRequired
            main={<NotFoundView />}
            fallback={<SetNewPasswordView />}
          />
      },
      {
        path: "/reset-password/",
        element:
          <AuthRequired
            main={<NotFoundView />}
            fallback={<ResetPasswordView />}
          />
      },
      {
        path: "account",
        element:
          <AuthRequired
            main={<AccountView />}
            fallback={<Login />}
            permissionParams={["user", "view_profile"]}
          />
      },
      {
        path: "logout",
        element:
          <AuthRequired
            main={<LogoutView />}
            fallback={<Login />}
          />
      },
      {
        path: "*",
        element: <NotFoundView />,
      }
    ]
  }
]);