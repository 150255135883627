import { createSvgIcon, styled } from "@mui/material";

const EBLCloudLogo = createSvgIcon(
    <text 
        xmlns="http://www.w3.org/2000/svg"
        font-weight="700"
        text-anchor="middle"
        font-family="'Arial', sans-serif"
        font-size="90"
        id="svg_1"
        width="100%" 
        height="100%"
        x="50%" 
        y="90"
        stroke-width="0"
        stroke="currentColor"
        fill="currentColor">
        EBLCloud
    </text>,
    "EBLCloudLogo"
);

type StyledEBLCloudLogoProps = {
    size: "inherit" | "small" | "medium" | "large";
};

export const EBLCloudLogoStyled = styled(EBLCloudLogo, { shouldForwardProp: (prop) => prop !== "size" })<StyledEBLCloudLogoProps>
    (({ size }) => ({
        width: "100%",
        ...(size === "small" && {
            margin: "13px 16px",
            width: "142px",
            height: "22px",
        }),
        ...(size === "medium" && {
            margin: "32px 40px",
            width: "200px",
            height: "36px",
        }),
        ...(size === "large" && {
            width: "100%",
            height: '100%'
        }),
    })
    );

EBLCloudLogoStyled.defaultProps = {
    viewBox: "0 0 500 100",
}

export default EBLCloudLogo;