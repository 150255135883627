import { ILocalOffice } from "./userManagement";

export interface IGateway {
    mac_address: string;
    disconnected: string | null;
    connected?: boolean;
    id: string;
    status: "alarm_system_totally_disconnected" | "disconnected" | "connected" | "unconfigured" | "waiting";
    gw_version: string;
    gateway_current_time?: string;
    gateway_timezone?: string;
}

export interface ICreateGateway {
    mac_address: string;
    fire_alarm_system: string;
}

interface IDeviationPayloadDataParameters {
    faulttype: number;
    zone: number;
    address?: number;
    techno: string;
    alarmstate?: number;
    alarmsource?: number;
    hausalarm?: boolean;
    expboard?: number;
    output?: number;
    reason?: number;
    type?: number;
    autohour?: number;
    autominute?: number;
    autore?: boolean;
    area?: number;
    point?: number;
    inputactive?: boolean;
    outputactive?: boolean;
    unit?: number;
    fbp?: number;
    dirtybyage: boolean;
    zoneaddress_desc?: string;
    alarmstate_desc?: string;
    alarmsource_desc?: string;
    areapoint_desc?: string;
    secondzoneaddress_desc?: string;
    zone_desc?: string;
    smokeonly: boolean;
}

interface IDeviationPayloadData {
    devid: number;
    faultstate?: number;
    faultstate_desc?: string;
    parameters: IDeviationPayloadDataParameters;
}

export interface IDeviationPayload {
    deviation_key: number;
    timestamp: string;
    active: boolean;
    text: string;
    devtype: string;
    data: IDeviationPayloadData;
}

export interface IDeviation {
    id: string;
    source: string;
    version: number;
    type: string;
    user: string;
    datetime: string;
    seq_ack: number;
    subject: string;
    payload: IDeviationPayload;
}

export type TDeviationType =
    | "fire_alarms"
    | "pre_warnings"
    | "faults"
    | "disablements"
    | "tech_warnings"
    | "interlocking"
    | "door_open"
    | "test_zones"
    | "service_signals"
    | "output_activations"
    | "web_disablement"
    | "communication_error";

export type TDeviationSubject =
    | "dev/disablement"
    | "dev/fault"
    | "dev/firealarm"
    | "dev/dirtysensor"
    | "dev/interlocking"
    | "dev/techwarn"
    | "dev/testmodealarmzone";

interface ICommandParameter {
    zone: number;
    address: number;
    autore: boolean;
    autohour?: string;
    autominute?: string;
    smokeonly: boolean
}

interface ICommandPayloadData {
    disable: boolean;
    parameters: ICommandParameter;
}

export interface ICommandPayload {
    cmdtype: string;
    jobid: string;
    data: ICommandPayloadData;
}

export enum CommandStatus {
    "FAILED" = 0,
    "EXECUTING" = 1,
    "COMPLETED" = 2,
    "PENDING" = 3,
    "TIMEDOUT" = 4
}

export interface ICommand {
    fire_alarm_system: string;
    id: string;
    source: string;
    version: number;
    type: string;
    user: string;
    datetime: string;
    seq_ack: number;
    subject: string;
    status: CommandStatus;
    reason: string;
    executed_by_email: string;
    payload: ICommandPayload;
}

export interface ICreateCommand {
    fire_alarm_system: string;
    source: string;
    version: number;
    type: string;
    user: string;
    seq_ack: number;
    subject: string;
    reason: string;
    payload: ICommandPayload;
}

export interface IBaseFireAlarmSystem {
    name: string;
    address: string;
    city: string;
    country: string;
    zip_code: string;
    description: string;
    organization?: string;
    local_office: string;
    local_office_details?: ILocalOffice;
    central_station_current_time?: string;
    server_current_time?: string;
}

export interface IFireAlarmDeviations {
    fire_alarms: IDeviation[];
    pre_warnings: IDeviation[];
    faults: IDeviation[];
    disablements: IDeviation[];
    tech_warnings: IDeviation[];
    interlocking: IDeviation[];
    door_open: IDeviation[];
    test_zones: IDeviation[];
    service_signals: IDeviation[];
    output_activations: IDeviation[];
    web_disablement: IDeviation[];
    communication_error: IDeviation[];
}

export interface IFireAlarmSystem extends IBaseFireAlarmSystem {
    id: string;
    active: boolean;
    gateways: IGateway[];
    deviations: IFireAlarmDeviations;
}

export interface IActiveDeviationCount {
    disablement_count: number;
    fault_count: number;
    firealarm_count: number;
    dirtysensor_count: number;
    interlocking_count: number;
    techwarn_count: number;
    door_count: number;
    forceactivatedoutput_count: number;
    connectionerror_count: number;
    has_active_fire: boolean;
}

export interface IFireAlarmSystemContactPerson {
    id: string;
    role: string;
    name: string;
    email: string;
    phone: string;
    fire_alarm_system: string;
}

export interface INewCommandFields {
    zone: string;
    time: Date | null;
    address?: string;
    reason: string;
    smokeOnly: boolean;
}

export interface IEventPayload {
    event_text?: string,
    timestamp: string,
    user?: string,
    user_type?: string,
    description?: string,
    type?: string,
    raw_data?: [],
    origin?: string

}

export interface IEvents {
    id: string;
    user: string;
    subject: string;
    datetime: string;
    payload: IEventPayload;
}

export interface IDownloadEvents {
    timestamp_from?: Date | null,
    timestamp_to?: Date | null,
}

export interface IDownloadJournal {
    timestamp_from?: Date | null,
    timestamp_to?: Date | null,
}

export interface IJournal {
    id?: string,
    type?: string,
    created: string,
    entry_text: string,
    command_status?: string,
    command_reason?: string,
    written_by_email: string
}

export interface ICreateJournalEntry {
    entry_text: string,
    fire_alarm_system?: string
}

export interface ISensorInfo {
    id: string;
    user: string;
    subject: string;
    datetime: string;
    payload: ISensorPayload;
}

export interface ISensorPayload {
    sensor_items?: ISensorItems[];
}

export interface ISensorItems {
    type: string,
    techno: string,
    zoneaddress: string,
    mnt: string,
    week: string,
    perf: string,
    min: string,
    max: string,
    algo: string,
    instdate: string,
    mnleft: string
}

export interface INewSensorInfoCommandFields {
    techno: string;
    dir: number;
}

interface ISensorInfoParameter {
    techno: string;
    dir: number;
}

interface ISensorInfoCommandPayloadData {
    parameters: ISensorInfoParameter;
}

export interface ISensorInfoCommandPayload {
    cmdtype: string;
    jobid: string;
    data: ISensorInfoCommandPayloadData;
}

export interface ICreateSensorInfoCommand {
    fire_alarm_system: string | null;
    source: string;
    version: number;
    type: string;
    user: string;
    seq_ack: number;
    subject: string;
    payload: ISensorInfoCommandPayload;
}

export interface ISensorInfoCommand {
    fire_alarm_system: string;
    id: string;
    source: string;
    version: number;
    type: string;
    user: string;
    datetime: string;
    seq_ack: number;
    subject: string;
    status: CommandStatus;
    executed_by_email: string;
    payload: ISensorInfoCommandPayload;
}

export interface ISSDFile {
    fire_alarm_system: string;
    id: string;
    timestamp: string;
    version: number;
    based_on_version?: number;
    uploaded_by_email: string;
    description: string;
}