import { CommandStatus } from "interfaces/fireAlarmSystem";


const useGetNewProgressOfStatus = (current: number, commandStatus: CommandStatus): number => {
    const randomIntFromInterval = (min: number, max: number) => {
        return Math.floor(Math.random() * (max - min + 1) + min)
    };

    switch (commandStatus) {
        case CommandStatus.PENDING:
            return randomIntFromInterval(current, 20)
        case CommandStatus.EXECUTING:
            return randomIntFromInterval(current, 99)
        case CommandStatus.TIMEDOUT:
        case CommandStatus.FAILED:
        case CommandStatus.COMPLETED:
            return 100
        default:
            return 0
    }

};

export default useGetNewProgressOfStatus;