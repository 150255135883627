import { BottomNavigationAction, Drawer, IconButton, ListItemButton, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const openDrawerWidth = 312;
export const closedDrawerWidth = 88;
type OpenProps = {
    open?: boolean;
};

export const NavigationListButton = styled(ListItemButton)
    (({theme}) => ({
        color: theme.palette.primary.main,
        width: "100%",
        margin: "0px 8px",
        borderRadius: "4px",
        ".MuiListItemText-root": {
            margin: 0,
        },
        "&.Mui-selected": {
            backgroundColor: theme.palette.background.default
        },
        "&:hover": {
            ".MuiTypography-root": {
                color: "white",
            },
            ".MuiSvgIcon-root": {
                color: "white",
            },
            backgroundColor: theme.palette.primary.main
        }
    })
);

export const NavigationDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })<OpenProps>
    (({ open }) => ({
        width: closedDrawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            border: "none",
            width: closedDrawerWidth,
            boxSizing: 'border-box',
        },
        ...(open && {
            width: openDrawerWidth,
            '& .MuiDrawer-paper': {
                border: "none",
                width: openDrawerWidth,
            },
        })
    })
);


export const NavigationIconButton = styled(IconButton, { shouldForwardProp: (prop) => prop !== 'open' })<OpenProps>
    (({open, theme}) => ({
        position: "fixed",
        zIndex: 1201,
        top: "82px",
        width: "36px",
        color: theme.palette.primary.light,
        left: `${open ? (openDrawerWidth - 18) : (closedDrawerWidth - 18)}px`,
        backgroundColor: theme.palette.background.default,
        border: `1px solid ${theme.palette.primary.light}`,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.background.default,
        }
    })
);

export const NavigationContentContainer =  styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<OpenProps>
    (({ theme, open }) => ({
        flexGrow: 1,
        [theme.breakpoints.up("sm")]: {
            padding: "32px 64px",
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: `${closedDrawerWidth}px`,
            ...(open && {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginLeft: `${openDrawerWidth}px`,
            })
        },
        [theme.breakpoints.down("sm")]: {
            padding: "1em",
            marginBottom: "calc(56px + env(safe-area-inset-bottom))"
        }
    })
);


export const MobileNavigationAction = styled(BottomNavigationAction)
    (({theme}) => ({
        minWidth: "70px",
        "&.Mui-selected": {
            color: theme.palette.background.default,
            ".MuiSvgIcon-root": {
                color: "white",
            },
            ".MuiBottomNavigationAction-label": {
                fontSize: "12px",
            }
        },
        "&.Mui-disabled": {
            color: theme.palette.primary.main,
            opacity: 0.2,
            ".MuiSvgIcon-root": {
                color: theme.palette.primary.main,
                opacity: 0.2,
            },
        }
    })
);

export const BottomNavigationWrapper = styled(Paper)
    (() => ({
        borderRadius: 0, 
        width: "100%", 
        padding: "0 6px", 
        position: 'fixed', 
        bottom: 0,
        zIndex: 10
    })
);