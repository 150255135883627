import React from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createCommand, fetchCommand } from "adapters/fireAlarmSystem";
import { CommandStatus, ICreateCommand } from "interfaces/fireAlarmSystem";
import useGetNewProgressOfStatus from "./useGetNewProgressOfStatus";

interface UseOperationsMethods {
    createOperation: (data: ICreateCommand) => void;
    isMutating: boolean;
    isExecuting: boolean;
    progress: number;
}

const useOperations = (onSuccess: () => void, onError: (status: any) => void): UseOperationsMethods => {
    const [currentExecutingCommand, setCurrentExecutingCommand] = React.useState<string>("");
    const [currentProgress, setcurrentProgress] = React.useState(0);
    const [isExecuting, setIsExecuting] = React.useState(false);
    const queryClient = useQueryClient();
    const getNewProgressOfStatus = useGetNewProgressOfStatus;

    const resetState = () => {
        setIsExecuting(false);
        setcurrentProgress(0);
        setCurrentExecutingCommand("");
    };

    const commandMutation = useMutation({
        mutationFn: createCommand,
        onMutate: () => {
            setIsExecuting(true);
        },
        onSuccess: (res) => {
            queryClient.invalidateQueries({ queryKey: ['commands'] });
            setCurrentExecutingCommand(res.data.id)
        },
        onError: (e, commanStatus) => {
            resetState();
            onError(commanStatus);
        }
    });


    useQuery({
        queryKey: ["command", currentExecutingCommand],
        queryFn: async () => {
            const queryResult = await fetchCommand(currentExecutingCommand)
            const commandStatus = queryResult.data.status;
            setcurrentProgress((current) => getNewProgressOfStatus(current, commandStatus));
            if (commandStatus === CommandStatus.COMPLETED) {
                resetState();
                onSuccess();
                await new Promise(resolve => setTimeout(resolve, 5000));
                queryClient.invalidateQueries({ queryKey: ["deviations"] }, { cancelRefetch: true });
                queryClient.invalidateQueries({ queryKey: ["fireAlarmSystems"], refetchType: 'all' }, { cancelRefetch: true });
                queryClient.invalidateQueries({ queryKey: ["activeDeviations"] }, { cancelRefetch: true });
            } else if ([CommandStatus.FAILED, CommandStatus.TIMEDOUT].includes(commandStatus)) {
                resetState();
                onError(commandStatus)
            }
            return queryResult ?? null
        },
        enabled: currentExecutingCommand !== "",
        refetchInterval: 1000
    });

    const createOperation = (data: ICreateCommand) => {
        commandMutation.mutate(data);
    };

    return {
        createOperation,
        isMutating: commandMutation.isPending,
        isExecuting,
        progress: currentProgress
    }
};

export default useOperations;